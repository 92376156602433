import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import CreateProductInformationCommand
  from '@/modules/flagship/investment-product/product-information/application/commands/create-product-information-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  ProductInformationDto,
} from '@/modules/flagship/investment-product/product-information/domain/dtos/product-information-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipProfilingSowosPocketViewModel {
  @Inject(TYPES.CREATE_PRODUCT_INFORMATION_COMMAND)
  private readonly create_product_info_command!: CreateProductInformationCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_sowos_pocket';

  show_poll_modal = false;

  readonly service = 'kuspit';

  showPollModal() {
    this.show_poll_modal = true;
  }

  updateInformation = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      const product_information_dto: ProductInformationDto = {
        investment_product_id: investor_profile.recommended_investment_product_id,
      };
      await this.create_product_info_command.execute(product_information_dto);

      window.location.href = '/';

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
      return false;
    }
  }
}
